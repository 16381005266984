@media all and (min-width: 320px) {
	.modal-dialog {
		max-width: 95%;
	}
}
@media all and (min-width: 768px) {
	.modal-dialog {
		min-width: 550px;
	}
}
@media all and (min-width: 1024px) {
	.modal-dialog {
		min-width: 660px;
	}
}

.modal-dialog {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  -webkit-transform: translate3d(0,0,2px);
}

.modal {
  text-align: center;
  -webkit-transform: translate3d(0,0,2px);
}

.modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
}

.mdaol-body {
	margin-top: 0px;
}